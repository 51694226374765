/**
* Created by hansxing on 2019/3/13.
*/
<style lang="scss" scoped>
.assign-driver {
  .assign-driver_sect {
    padding: 12px 0;
  }

  .driver_sect_info {
    padding: 10px 12px 20px 12px;
    font-size: 16px;
  }

  .driver-license-plate {
    display: flex;
    background: #fff;
    padding: 0 15px;
    font-size: 17px;

    p {
      min-width: 103px;
      line-height: 50px;
    }

    .territory {
      /*width: 73px;*/
      .mint-button {
        border: 0;
        padding: 16px 10px;
        width: 100%;
        font-size: 17px;
        color: #319BF7;
        height: 50px;

        &:after {
          border: 0;
          background: transparent;
        }

        &.mint-button--default {
          box-shadow: none;
          background: transparent;
        }

        &.mint-button--normal {
          padding: 0;
        }
      }
    }

    .plate-num {
      flex: 1;

      input {
        font-size: 17px;
      }
    }
  }

  .driver-foot-btn {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;

    .driver-submit {
      background-image: linear-gradient(-90deg, #4CDBF5 2%, #50A7F2 100%);
      font-size: 18px;
      width: 100%;

      &.mint-button {
        border: 0;
        border-radius: 0;
        height: 100%;
      }
    }
  }

  .driver-plate-city {
    background: #E5E5E5;

    li {
      height: 50px;
      line-height: 50px;
      width: 12.5%;
      float: left;
      font-size: 18px;
      text-align: center;
      background: #fff;
      position: relative;

      &.plate-city-select {
        background: #319BF7;
        color: #fff;
      }

      &:after {
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        width: 1px;
        background: #e5e5e5;
      }

      &:nth-child(8n) {
        &:after {
          width: 0;
        }
      }

      &:before {
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: #e5e5e5;
      }

    }
  }

  .driver-info-res {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    max-height: 150px;
    z-index: -10;
    opacity: 0;
    font-size: 18px;
    background: #fff;
    border: 1px solid #E5E5E5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    transition: opacity 200ms;
    -webkit-transition: opacity 2s;
    overflow: hidden;
    overflow-y: scroll;

    &.info-res_z {
      z-index: 10;
      opacity: 1;
    }

    li {
      line-height: 50px;
      padding: 0 15px;
      border-bottom: 1px solid #f8f8f8;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .mod-confirm-footer {
    display: flex;
    position: relative;

    .mint-button {
      flex: 1;
      border: 0;
      font-size: 17px;
      border-radius: 0;
      background: #fff;
      color: #007AFF;
      height: 44px;
      line-height: 44px;
      padding: 0;

      &:first-child {
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
      }

      &:after {
        border: 0;
        background: transparent;
      }

      &.mint-button--default {
        box-shadow: none;
        background: transparent;
      }

      &.mint-button--normal {
        padding: 0;
      }

    }

    /*.mint-button + .mint-button {*/
    /*    margin-left: 0;*/
    /*}*/
    &:before {
      content: " ";
      display: block;
      height: 1px;
      background: #C8C7CC;
      position: absolute;
      left: 0;
      right: 0;
      top: -1px;
    }

    &:after {
      content: " ";
      display: block;
      background: #C8C7CC;
      position: absolute;
      left: 50%;
      width: 1px;
      top: 0;
      bottom: 0;
      margin-left: -1px;
    }
  }
}

.assign-driver /deep/ {
  .assign-driver_sect {
    .el-form-item {
      margin-bottom: 0;
      border-bottom: 1px solid #f8f8f8;

      &:last-child, &:nth-last-child(2) {
        border-bottom: 0;
      }
    }

    .el-input__inner {
      height: 50px;
      line-height: 50px;
      font-size: 17px;
      border: 0;
    }
  }

  .driver-public {
    background: #FFFFFF;
    position: relative;

    p {
      font-size: 17px;
      line-height: 50px;
    }

    .el-form-item__content {
      display: flex;
      flex-direction: row;
      padding: 0 15px;

      .el-input__inner {
        padding: 0;
      }

    }
  }

  .driver-public-name {
    p {
      min-width: 110px;
    }

    .public-name_input {
      flex: 1;
      font-size: 17px;
    }
  }

  .driver-public-phone {
    p {
      min-width: 110px;
    }

    .public-phone_input {
      flex: 1;
      font-size: 17px;
    }
  }

  .driver-public-price {
    p {
      min-width: 110px;
    }

    margin-top: 10px;
    position: relative;

    .public-price_input {
      flex: 1;
      font-size: 17px;
    }

    .public-price_t {
      position: absolute;
      right: 12px;
      font-size: 17px;
      line-height: 50px;
    }
  }

  .plate-num {
    .el-form-item__content {
      height: 50px;
      align-items: center;
      display: flex;
    }

    .el-input__inner {
      padding-left: 0;
    }
  }

  .mod-driver-plate {
    &.el-dialog__wrapper {
      overflow: hidden;
    }

    .mod-driver-plate_cus {
      &.el-dialog {
        margin: 0 !important;
      }

      width: 100%;
      bottom: 0;
      background: transparent;
      position: fixed;

      .el-dialog__header {
        display: none;
        opacity: 0;
        /*padding: 15px;*/
        /*border-bottom: 1px solid #f8f8f8;*/
      }

      .el-dialog__body {
        padding: 0;
      }
    }

  }


}

.mt-popup-type_cus {
  left: 0;
  right: 0;
  z-index: 99999;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &.popup-center {
    left: 10%;
    right: 10%;
    border-radius: 10px;
    margin-top: -100px;
    width: auto;

    h4 {
      font-size: 17px;
      color: #000000;
      font-weight: 600;
      padding: 20px 12px 0 12px;
    }

    .driver-confirm {
      padding: 10px 15px 20px 15px;

      li {

        font-size: 13px;
        color: #111111;
        line-height: 16px;

        &.driver-confirm_prompt {
          color: #FF3B30;
        }
      }
    }
  }

}


</style>
<template>

  <div class="assign-driver">
    <yellow-warn content="司导信息将推送给出行人，请填写真实信息！"></yellow-warn>
    <div class="assign-driver_sect">
      <div class="driver_sect_info" v-if="driverInfo.guiderName && driverInfo.guiderMobile">
        将原司导：{{ driverInfo.guiderName }}，{{ driverInfo.guiderMobile }}，{{
          driverInfo.carNumber
        }}，司机到手价￥{{ driverInfo.driverPrice | currency }}，更换为新司导：
      </div>
      <el-form :model="driverForm" ref="driverForm">
        <el-form-item class="driver-public driver-public-name">
          <p>司机姓名：</p>
          <!--<el-input v-model="driverForm.name" placeholder="司导姓名" @change="getDriverLib"></el-input>-->
          <input class="public-name_input" @input="onInputQuery(1)" placeholder="司导姓名"
                 v-model="driverForm.driverName">
          <div v-if="driverList && driverList.length > 0 && driverListShow == 1" class="driver-info-res"
               :class="{'info-res_z':driverList && driverListShow == 1}">
            <ul>
              <li v-for="(item, index) in driverList" :key="index" @click="handName(item)">
                {{ item.driverName }}&nbsp;&nbsp;&nbsp;{{ item.driverMobile }}
              </li>
            </ul>
          </div>

        </el-form-item>
        <el-form-item class="driver-public driver-public-phone">
          <p>司导手机号：</p>
          <!--<el-input v-model="driverForm.driverMobile" maxlength="11" placeholder="司导手机号"></el-input>-->
          <input class="public-phone_input"
                 @input="onInputQuery(2)"
                 @blur="onInputBlur()"
                 placeholder="司导手机号" type="tel"
                 v-model="driverForm.driverMobile"
                 maxlength="11">
          <div v-if="driverList && driverList.length > 0 && driverListShow == 2" class="driver-info-res"
               :class="{'info-res_z':driverList && driverListShow == 2}">
            <ul>
              <li v-for="(item, index) in driverList" :key="index" @click="handName(item)">
                {{ item.driverName }}&nbsp;&nbsp;&nbsp;{{ item.driverMobile }}
              </li>
            </ul>
          </div>
        </el-form-item>
        <div class="driver-license-plate">
          <p>车牌号：</p>
          <el-form-item class="territory">
            <mt-button @click="regionBtn">{{ driverForm.region }}<i class="icon icon-bottomArrow"
                                                                    style="margin-left: 8px"></i>
            </mt-button>
          </el-form-item>
          <el-form-item class="plate-num">
            <input v-model="driverForm.plateNum" maxlength="7" placeholder="车牌号">
          </el-form-item>
        </div>
        <div class="driver-license-plate">
          <!--<p>品牌型号：</p>-->
          <p class="clear" style="width: 100%;position: relative" @click="brandShow = true">
            品牌型号：
            <span style="font-size: 14px;" :style="{color: driverForm.carType?'#333':'#b1b1b1'}">
                            {{
                driverForm.carType ? (driverForm.carBrand + '/' + driverForm.carModel) : '请选择品牌型号'
              }}</span>
            <span style="color: #999;position: absolute;right: -5px;top: 0px;font-size: 12px"><i
                class="icon icon-small-arrow-right"></i></span></p>
          <!--<el-cascader placeholder="品牌" v-model="driverForm.carBrandModel"-->
          <!--:options="brandModelLsit" style="width: 100%" @change="selectCarBrandModel"-->
          <!--&gt;</el-cascader>-->
        </div>
        <el-form-item class="driver-public driver-public-name">
          <p>车辆颜色：</p>
          <!--<el-input v-model="driverForm.name" placeholder="司导姓名" @change="getDriverLib"></el-input>-->
          <input class="public-name_input" placeholder="车辆颜色"
                 v-model="driverForm.carColor">
        </el-form-item>

        <el-form-item class="driver-public driver-public-price">
          <p>司机到手价：</p>
          <input class="public-price_input" type="number" v-model="driverForm.arrPrice"
                 placeholder="司机到手价">
          <span class="public-price_t">元</span>
        </el-form-item>
      </el-form>


      <div class="driver-foot-btn">
        <mt-button class="driver-submit" type="primary" @click="submitBtn()">确定</mt-button>
      </div>
    </div>
    <mt-popup
        v-model="regionShow"
        popup-transition="popup-fade"
        position="bottom"
        class="mt-popup-type_cus"
    >
      <ul class="driver-plate-city clear">
        <li v-for="list in plateCity"
            :key="list.value"
            :class="{'plate-city-select':driverForm.region == list.value}"
            @click="plateCityBtn(list)">
          {{ list.value }}
        </li>
      </ul>
    </mt-popup>
    <!--        <el-dialog-->
    <!--                :close-on-click-modal="false"-->
    <!--                :show-close="false"-->
    <!--                :center="true"-->
    <!--                custom-class="mod-driver-plate_cus"-->
    <!--                class="mod-driver-plate"-->
    <!--                :visible.sync="regionShow">-->
    <!--            <ul class="driver-plate-city clear">-->
    <!--                <li v-for="list in plateCity"-->
    <!--                    :key="list.value"-->
    <!--                    :class="{'plate-city-select':driverForm.region == list.value}"-->
    <!--                    @click="plateCityBtn(list)">-->
    <!--                    {{list.value}}-->
    <!--                </li>-->
    <!--            </ul>-->
    <!--        </el-dialog>-->

    <mt-popup
        v-model="confirmShow"
        popup-transition="popup-fade"
        class="mt-popup-type_cus popup-center"
    >
      <h4>确认分配司导信息</h4>
      <ul class="driver-confirm">
        <li>将订单分配给：</li>
        <li>司导姓名：{{ driverForm.driverName }}</li>
        <li>司导手机号：{{ driverForm.driverMobile }}</li>
        <li>车牌号：{{ driverForm.region }}{{ driverForm.plateNum }}</li>
        <li>品牌型号：{{ driverForm.carBrand }}/{{ driverForm.carModel }}</li>
        <li>车辆颜色：{{ driverForm.carColor }}</li>
        <li>司机到手价：￥{{ driverForm.arrPrice | currency }}</li>
        <!--                <li class="driver-confirm_prompt" v-if="driverLibShow">-->
        <!--                    平台将把订单推送至900游司导端APP，请提醒司机登录查看！-->
        <!--                </li>-->
        <!--                <li class="driver-confirm_prompt" v-else>-->
        <!--                    该司机未注册900游司导端APP，将无法接收订单信息。请提醒司机立即注册！-->
        <!--                </li>-->
      </ul>
      <div class="mod-confirm-footer">
        <mt-button @click="confirmShow = !confirmShow">取 消</mt-button>
        <mt-button @click="confirmBtn">确 定</mt-button>
      </div>
    </mt-popup>
    <mt-popup v-model="brandShow" :modal="false"
              popup-transition="popup-fade" style="width: 100%">
      <div>
        <brand-model @selectCar="selectCarBrandModel" is-title v-model="brandShow"></brand-model>
      </div>
    </mt-popup>
  </div>

</template>

<script type="text/ecmascript-6">
import {onlyChineseAndLetter, isCellphone, onlyLettersOrNumber} from '@/common/js/Utils';
import plateCity from '@/common/js/data/plateCity.json';
import yellowWarn from '../../../components/yellowWarn';
import {Toast, Button, Popup} from 'mint-ui';
import BrandModel from '../../../components/brandModel'

export default {
  name:"AssignDriver",
  //定义模版数据
  data() {
    let userInfo = this.$store.state.loginUserInfo;
    return {
      userInfo: userInfo,
      driverInfo: JSON.parse(this.$route.params.driverInfo),
      driverForm: {
        driverName: '',  // 司导姓名
        driverMobile: '',  // 司导手机号
        region: '沪',  // 区域 车牌归属地
        plateNum: '',  // 车牌号码
        arrPrice: ''
      },
      regionShow: false,  // 选择车牌归属地
      plateCity: plateCity,
      confirmShow: false,  // 确认分配司导信息
      supplierId: '',  // old供应商id
      driverList: null,
      timer: null,
      driverListShow: '',  // 查询司导库value 1 name 2 phone
      driverLibShow: false,  //是否在司导库
      brandModelLsit: [],
      brandShow: false

    }
  },
  components: {
    yellowWarn,
    MtButton: Button,
    MtPopup: Popup,
    BrandModel
  },
  //计算属性
  computed: {},
  activated() {
    this.driverInfo = JSON.parse(this.$route.params.driverInfo);
//            this.getBrandList();
  },
  //主件被加载完成
  mounted: function () {


  },
  //定义事件方法
  methods: {
    regionBtn() {
      this.regionShow = !this.regionShow
    },
    plateCityBtn(v) {
      this.driverForm.region = v.value;
      this.regionBtn()
    },
    async submitBtn() {
      if (!onlyChineseAndLetter(this.driverForm.driverName))
        return Toast('姓名格式错误');
      if (!isCellphone(this.driverForm.driverMobile))
        return Toast('手机号格式错误');
      if (!onlyLettersOrNumber(this.driverForm.plateNum) || this.driverForm.plateNum.length < 5 || this.driverForm.plateNum.length > 7)
        return Toast('车牌号格式错误')
      if (!this.driverForm.carBrand || !this.driverForm.carModel)
        return Toast('请选择品牌型号');
      if (!this.driverForm.arrPrice)
        return Toast('请填写司机到手价');

      this.confirmShow = true;
      let form = {};
      form.agencyId = this.supplierId;
      form.mobile = this.driverForm.driverMobile;
      // let res = await this.http('/backend_server/guiderController/getGuiderByAssociate.do', form, '1', 'POST');
      // if (res.code == 99 && res.data && res.data.data.length > 0) {
      //     this.driverLibShow = true
      // }
    },
    async confirmBtn() {
      let form = {
        orderId: this.$route.params.orderId,
        guiderMobile: this.driverForm.driverMobile,
        guiderName: this.driverForm.driverName,
        carNumber: this.driverForm.region + this.driverForm.plateNum.toUpperCase(),
        driverId: this.driverForm.id,
        carBrand: this.driverForm.carBrand,
        carModel: this.driverForm.carModel,
        carColor: this.driverForm.carColor,
        carType: this.driverForm.carType,
        carOwner: this.driverForm.carOwner,
        driverPrice: this.driverForm.arrPrice,
        supplierId: this.driverForm.driverSupplierId,
        carId: this.driverForm.carId,
        idCardNumber: this.driverForm.idCardNumber,
        sourceType: this.$route.params.sourceType,
      }
      let res = await this.http('/galaxyOrder/distributionGuider', form, "POST");
      if (res.success && res.data) {
        this.$store.dispatch('changeOrderStatus', {
          orderId: this.$route.params.orderId,
          orderStatus: 41
        })
        this.confirmShow = false;
        this.$router.replace({
          name: 'orderManage'
        });
//                    this.driverForm = {
//                        name: '',  // 司导姓名
//                        driverMobile: '',  // 司导手机号
//                        region: '沪',  // 区域 车牌归属地
//                        plateNum: '',  // 车牌号码
//                        arrPrice: ''
//                    };
      }

    }
    ,
    onInputQuery(value) {
      clearTimeout(this.timer);
      this.driverListShow = value;
      this.timer = setTimeout(() => {
        this.driverList = null;
        if (value === 1) {
          if (this.driverForm.driverName && this.driverForm.driverName.length > 1) {
            this.getDriverLib(1);
          }
        }
        if (value === 2) {
          if (this.driverForm.driverMobile && isCellphone(this.driverForm.driverMobile)) {
            this.getDriverLib(2);
          }
        }
      }, 100);
    }
    ,
    async getDriverLib(type) {
      let form = {};
      if (type === 2)
        form.driverMobile = this.driverForm.driverMobile;
      if (type === 1)
        form.driverName = this.driverForm.driverName;
      let res = await this.http('/galaxyOrder/getDriverListBySupplier', form, 'POST');
      if (res.success && res.data) {
        this.driverList = res.data
      }
    },
    //丢失焦点自动带入
    onInputBlur() {
      if (this.driverList && this.driverList.length > 0)
        this.handName(this.driverList[0]);
    },
    handName(item) {
      this.driverForm = item;
      if (item.carNumberPlate) {
        this.driverForm.region = item.carNumberPlate.substring(0, 1);
        this.driverForm.plateNum = item.carNumberPlate.substr(1);
//                    this.driverForm.carBrandModel = [item.carBrand, item.carModel]
      }

      this.driverList = null;
    },
    selectCarBrandModel(val) {
      // console.log(val, 1111111111111);
//                const brand = this.brandModelLsit.find(b=>{
//                    return (val[0]===b.value);
//                })
//                const model = brand.children.find(c=>{
//                    return (val[1]===c.value);
//                })
      this.driverForm.carModel = val.model;
      this.driverForm.carBrand = val.brand;
      this.driverForm.carType = val.carType;
      this.driverForm.driverSupplierId = val.driverSupplierId;
      this.brandShow = false;
      // console.log(model, 1111111111);
    }
  },
  //监听模版变量
  watch: {}

}
</script>
